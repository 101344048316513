import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"

const LinkMap = {
  //Concepts
  Algoritma: "5f14316890a4",
  "Süre Karmaşıklığı": "5f14316890a4",
  "Bellek Alan Karmaşıklığı": "5f14316890a4",
  "Worst case": "5f14316890a4",
  "Θ Theate Notasyonu": "5f14316890a4",
  "Ω Omega notasyonu": "5f14316890a4",
  "Constant Complexity": "5f14316890a4",
  "Logarithmic Complexity": "5f14316890a4",
  "Linear Complexity": "5f14316890a4",
  "Quadratic Complexity": "5f14316890a4",
  "Cubic Complexity": "5f14316890a4",
  "Exponential Complexity": "5f14316890a4",

  "Veri Yapıları": "a265f6a4ab04",
  Search: "a265f6a4ab04",
  Access: "a265f6a4ab04",
  Insertion: "a265f6a4ab04",
  Deletion: "a265f6a4ab04",

  Array: "a265f6a4ab04",
  Stack: "a265f6a4ab04",
  "Single Linked List": "a265f6a4ab04",
  "Double Linked List": "a265f6a4ab04",
  "Skip List": "a265f6a4ab04",
  "Hash Table": "a265f6a4ab04",
  "Binary Search Tree": "a265f6a4ab04",
  "Cartesian Tree": "a265f6a4ab04",
  "B-Tree": "a265f6a4ab04",
  "Red-Black Tree": "a265f6a4ab04",
  "Splay Tree": "a265f6a4ab04",
  "AVL Tree": "a265f6a4ab04",

  "SHA-1": "6ef64a903ea5",
  MD5: "6ef64a903ea5",
  DES: "6ef64a903ea5",
  AES: "6ef64a903ea5",
  RSA: "6ef64a903ea5",
  "Simetrik Şifreleme": "6ef64a903ea5",
  "Asimetrik Şifreleme": "6ef64a903ea5",
  "Private Key": "6ef64a903ea5",
  "e-İmza": "6ef64a903ea5",
  "SSL/TLS": "6ef64a903ea5",
  "LUHN Alg": "6ef64a903ea5",

  "Tree Structure": "4a1bdf423024",
  "Binary Search Tree Structure": "4bf9e08f9859",

  "BST Traversal": "7e46791ad8f3",
  "Tree PreOrder PostOrder InOrder": "7e46791ad8f3",

  "BST MaxDepth": "7e46791ad8f3",
  "BST TopView Algorithm": "45fba2a17223",

  LinkedList: "c5ac1f5f7202",
  "LinkedList Circular": "c5ac1f5f7202",
  "LinkedList Cyclic Loop": "58ebc0993172",

  "Hanoi Towers": "a74bf4e7a48f",

  "Brute Force Algorithms": "d925ba311857",
  "Exhaustive Search": "d925ba311857",
  "Greedy Algorithms": "d925ba311857",
  "Dynamic Programming Algorithms": "d925ba311857",
  "Divide And Conquer Algorithm": "d925ba311857",
  "Graph Algorithm": "d925ba311857",
  "Combinatorial Pattern Matching": "d925ba311857",
  "Clustering And Trees": "d925ba311857",
  "Hidden Markov Models": "d925ba311857",
  "Randomized Algorithms": "d925ba311857",
}

const storiesGroup = [
  {
    title: "Konsepts",
    storyPart: [
      {
        title: "Algoritma Karmaşıklığı (Big-O)",
        postId: "5f14316890a4",
      },
      {
        title: "Veri Yapılarında Big-O",
        postId: "a265f6a4ab04",
      },
      {
        title: "Şifreleme (Encryption)",
        postId: "6ef64a903ea5",
      },
      {
        title: "Hanoi Kuleleri",
        postId: "a74bf4e7a48f",
      },
      {
        title: "BioInformatics Algorithms",
        postId: "d925ba311857",
      },
    ],
  },
  {
    title: "Samples",
    storyPart: [
      {
        title: "DayOf X Algorithm",
        postId: "66037a085209",
      },
      {
        title: "Linked List - Circular",
        postId: "c5ac1f5f7202",
      },
      {
        title: "Linked List - Cyclic Loop",
        postId: "58ebc0993172",
      },
      {
        title: "Tree Structure",
        postId: "4a1bdf423024",
      },
      {
        title: "Binary Search Tree",
        postId: "4bf9e08f9859",
      },
      {
        title: "BST Traversal",
        postId: "7e46791ad8f3",
      },
      {
        title: "BST Max Depth",
        postId: "45fba2a17223",
      },
      {
        title: "BST TopView",
        postId: "29b9ab719472",
      },
    ],
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "Algoritma Veri Yapısı",
  path: "algoritma-veriyapisi",
}

const AlgAndDataStructurePage = () => (
  <StoryGroup
    complex={true}
    title={context.title}
    stories={storiesGroup}
    linkMap={context.linkMap}
  />
)

export default AlgAndDataStructurePage
